<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="sliderForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="sliderForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="Active"
            label-for="Active"
          >
            <b-form-checkbox
              id="Active"
              v-model="sliderForm.is_active"
              :value="1"
              :unchecked-value="0"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="Has Action"
            label-for="Has Action"
          >
            <b-form-checkbox
              id="Has Action"
              v-model="sliderForm.has_action"
              :value="1"
              :unchecked-value="0"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sliderForm.has_action==1"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Action Type"
            rules="required"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Action Type"
            >
              <b-form-radio-group
                v-model="sliderForm.type"
                :aria-describedby="ariaDescribedby"
                name="type"
                @change="clearActionTypeData()"
              >
                <b-form-radio

                  value="1"
                >
                  Winners
                </b-form-radio>
                <b-form-radio

                  value="2"
                >
                  Campaign
                </b-form-radio>
                <b-form-radio

                  value="3"
                >
                  Products
                </b-form-radio>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-radio-group>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col
          v-if="sliderForm.type==3 && sliderForm.has_action==1"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="products"
            rules="required"
          >
            <b-form-group
              label="products"
              label-for="products"
            >
              <v-select
                v-model="sliderForm.product_id"
                :options="products"
                :filterable="false"
                label="name"
                :reduce="products => products.id"
                @search="searchProducts"
                @input="getCampaignsByProductId(sliderForm.product_id)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="sliderForm.type==2 && sliderForm.has_action==1"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="product_gifts"
            rules="required"
          >
            <b-form-group
              label="Campaign (id-name)"
              label-for="product_gifts"
            >
              <v-select
                v-model="sliderForm.product_gift_id"
                label="product_name"
                :options="product_gifts"
                :filterable="false"
                :reduce="product_gifts => product_gifts.id"
                @search="searchproduct_gifts"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.id }} - {{ option.name }}
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.id }} - {{ option.name }}
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- v-if="$route.params.id===null" -->
        <b-col
          v-if="sliderForm.type==3 && sliderForm.has_action==1 && sliderForm.has_action==1&& sliderForm.product_id !==null"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="product_gifts"
            rules="required"
          >
            <b-form-group
              label="Campaign (id-name)"
              label-for="product_gifts"
            >
              <v-select
                v-model="sliderForm.product_gift_id"
                label="product_name"
                :options="campaignsByProductId"
                :filterable="false"
                :reduce="campaignsByProductId => campaignsByProductId.id"
                @search="searchproduct_gifts"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.id }} - {{ option.name }}
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.id }} - {{ option.name }}
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Slider"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Slider Type"
            >
              <b-form-radio-group
                v-model="fileType"
                :aria-describedby="ariaDescribedby"
                name="Slider"
              >
                <b-form-radio

                  value="1"
                >
                  Video
                </b-form-radio>
                <b-form-radio

                  value="2"
                >
                  Image
                </b-form-radio>
                <b-form-radio

                  value="3"
                >
                  Gif
                </b-form-radio>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-radio-group>
            </b-form-group>

          </validation-provider>
        </b-col>
        <b-col
          v-if="fileType==1"
          md="6"
        >
          <b-media
            no-body
            class="mb-1"
          >
            <video
              id="video-preview"
              controls
              width="300"
              class="mr-2"
            />

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 "
                @click="selectFile()"
              >
                Upload Video
              </b-button>
              <b-form-file
                ref="refInputEl"
                accept="video/*"
                :hidden="true"
                plain
                @change="handleFileUpload($event)"
              />
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          v-else
          cols="6"
        >
          <b-media>

            <b-media-aside>
              <b-col
                cols="3"
              >

                <div>

                  <b-img
                    ref="previewEl"
                    v-img
                    :src="sliderForm.video"
                    class=" productImg"
                    height="200"
                    width="400"
                  />

                </div>
              </b-col>

              <!--/ image -->
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload Image
              </b-button>
              (aspect ratio 2:1)
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png,.jpeg,.gif"
                :hidden="true"
                plain
                @change="changeProfile($event)"
              />
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id "
            variant="primary"
            class="mr-1"
            :disabled="
              invalid ||
                Object.values(errors).length > 0
            "
            @click="addSlider()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="
              invalid ||
                Object.values(errors).length > 0
            "
            @click="addSlider()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import vSelect from 'vue-select'

export default {
  components: { vSelect },
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      product_gifts: [],
      products: [],
      allSelected: [],
      campaignsByProductId: [],
      loader: false,
      file: '',
      errors: {},
      fileType: 1,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const sliderForm = ref({
      is_active: 0,
      has_action: 0,
      video: null,
    })
    // eslint-disable-next-line camelcase
    const file = ref('')
    // eslint-disable-next-line camelcase
    const slider_image = ref('')

    return {
      getValidationState,
      sliderForm,
      file,
      slider_image,
    }
  },
  mounted() {
    this.showSlider()
    this.getProducts()
    this.getproduct_gifts()
  },
  methods: {
    handleFileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      this.previewVideo()
    },

    previewVideo() {
      const video = document.getElementById('video-preview')
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.addEventListener('load', () => {
        video.src = reader.result
      })
    },
    clearActionTypeData() {
      this.sliderForm.product_id = null
      this.sliderForm.product_gift_id = null
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.slider_image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        const standard = 2 / 1
        const belowStandard = 2 / 1.05
        const userRatio = img.width / img.height
        if (userRatio <= standard && userRatio >= belowStandard) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.sliderForm.video = er.target.result
            }
            // eslint-disable-next-line prefer-destructuring
            this.slider_image = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image Aspect Ratio is Not Valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
    },
    async searchProducts(searchQuery) {
      await axios.get(`admin/products?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.products = res.data?.data.products
        }
      })
    },
    async getProducts() {
      await axios.get('admin/products?active=1').then(res => {
        if (res.status === 200) {
          this.products = res.data?.data.products
        }
      })
    },
    async searchproduct_gifts(searchQuery) {
      await axios.get(`admin/product_gifts?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.product_gifts
        }
      })
    },
    async getproduct_gifts() {
      await axios.get('admin/product_gifts?is_active=1').then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.products
        }
      })
    },
    async getCampaignsByProductId(productId) {
      if (productId) {
        await axios.get(`admin/product_gifts_of_product_id/${productId}`).then(res => {
          if (res.status === 200) {
            this.campaignsByProductId = res.data?.data.product_gifts
          }
        })
      }
    },
    showSlider() {
      if (this.$route.params.id) {
        const video = document.getElementById('video-preview')

        axios.get(`admin/sliders/${this.$route.params.id}`).then(res => {
          this.sliderForm = res.data.data.slider
          this.getCampaignsByProductId(this.sliderForm.product_id)
          const fileExtension = this.getFileExtension(res.data.data.slider.video)
          if (fileExtension === 'mp4') {
            video.src = res.data.data.slider.video
          } else if (fileExtension === 'gif') {
            this.fileType = 3
          } else {
            this.fileType = 2
          }
        })
      } else {
        return false
      }
      return true
    },
    removeNullProperties(obj) {
      const newObj = {}

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(obj)) {
        if (value !== null) {
          newObj[key] = value
        }
      }

      return newObj
    },
    getFileExtension(fileName) {
      return fileName.split('.').pop().toLowerCase()
    },
    addSlider() {
      if (this.$route.params.id) {
        this.loader = true

        const newSliderForm = this.removeNullProperties(this.sliderForm)
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in newSliderForm) {
          if (key !== 'video') {
            formData.append(key, newSliderForm[key])
          }
        }

        // eslint-disable-next-line eqeqeq
        if (newSliderForm.type == 1) {
          formData.delete('product_gift_id')
        }
        if (this.file) {
          formData.append('video', this.file)
        }
        if (this.slider_image) {
          formData.append('video', this.slider_image)
        }
        axios.post(`admin/sliders/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'sliders' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const newSliderForm = this.removeNullProperties(this.sliderForm)
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in newSliderForm) {
          if (key !== 'video') {
            formData.append(key, newSliderForm[key])
          }
        }

        // eslint-disable-next-line eqeqeq
        if (newSliderForm.type == 1) {
          formData.delete('product_gift_id')
        }
        if (this.file) {
          formData.append('video', this.file)
        }
        if (this.slider_image) {
          formData.append('video', this.slider_image)
        }
        this.loader = true
        axios.post('admin/sliders', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'sliders' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
